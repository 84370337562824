import {Button, makeStyles, tokens, Card, Text} from '@fluentui/react-components';
import PageHeader from '../../components/PageHeader';
import {usePopulatedTopbarValues} from '@axteams-one/populated-topbar';
import ResourceGroupTree from '../../components/ResourceGroupTree';
import {useSearchParams} from 'react-router-dom';
import PageContent from '../../components/PageContent';
import NoValidOrganization from '../../components/NoValidOrganization';
import {useAppSelector} from '../../store/hooks';

const useStyles = makeStyles({
  card: {
    paddingLeft: tokens.spacingHorizontalXXL,
    paddingTop: tokens.spacingVerticalXXL,
    paddingBottom: tokens.spacingVerticalXXL
  },
  row: {
    display: 'flex',
    columnGap: tokens.spacingHorizontalXS,
    alignItems: 'center'
  }
});

const openDID = (organizationArn: string, resourceGroup: string, traceparent: string | null) => {
  const searchParams = new URLSearchParams();
  searchParams.set('organizationArn', organizationArn);
  searchParams.set('resourceGroup', resourceGroup);
  if (traceparent) {
    searchParams.set('traceparent', traceparent);
  }
  window.open('/superset?' + searchParams.toString(), '_blank');
};

const Home = () => {
  const {organization, loaded} = usePopulatedTopbarValues();
  const styles = useStyles();
  const [searchParams] = useSearchParams();
  const {selectedResourceGroup} = useAppSelector(state => state.resourceGroupsSlice);

  if (!organization) {
    return <NoValidOrganization loaded={loaded} />;
  }

  return (
    <>
      <ResourceGroupTree />
      <PageContent data-testid="home-tab">
        <PageHeader title="Home" />
        <Card className={styles.card}>
          <Text>1. Select folder</Text>
          <div className={styles.row}>
            <Text>2.</Text>
            <Button
              data-testid="open-did-button"
              onClick={() =>
                openDID(organization.arn, selectedResourceGroup.id, searchParams.get('traceparent'))
              }
              appearance="primary"
            >
              Open Data Insights Dashboard
            </Button>
          </div>
        </Card>
      </PageContent>
    </>
  );
};

export default Home;
