import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  DataGrid,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  makeStyles,
  Spinner,
  Switch,
  DataGridBody,
  tokens,
  DataGridCell,
  createTableColumn,
  Text,
  ProgressBar,
  TableColumnDefinition
} from '@fluentui/react-components';
import {ActionDisplayName, AoAScenarioInfo, DeviceInfo, ScenarioType} from '../../types';
import Panel from '../../components/Panel';
import useToasts from '../../hooks/useToasts';
import {usePopulatedTopbarValues} from '@axteams-one/populated-topbar';
import {useOpenTelemetry} from '@axteams-one/opentelemetry-js-react';
import {ScenarioTagPicker} from '../../components/ScenarioTagPicker';
import {assignTag, createTag, deleteTag, enableReporting, unassignTag} from '../../fetcher';
import {Dispatch, SetStateAction, useState} from 'react';
import PromiseQueue from '../../helpers/promiseQueue';
import {EmptyView} from '../../components/EmptyView';
import {CameraDome28Regular} from '@fluentui/react-icons';

const useStyles = makeStyles({
  accordionHeader: {
    background: tokens.colorNeutralBackground2
  },
  columnText: {
    color: tokens.colorNeutralForeground3
  },
  spinner: {
    justifyContent: 'center',
    paddingLeft: tokens.spacingHorizontalM
  },
  dataGrid: {
    paddingTop: tokens.spacingHorizontalL
  },
  progressBarContainer: {
    minHeight: tokens.spacingVerticalXXS
  }
});

interface ScenarioPanelProps {
  readonly scenarios?: AoAScenarioInfo[];
  readonly device?: DeviceInfo;
  readonly setScenarios: Dispatch<SetStateAction<AoAScenarioInfo[] | undefined>>;
  readonly tagOptions?: {
    name: string;
    assigned_scenarios_count: number;
  }[];
  readonly panelOpen: boolean;
  readonly onClose: () => void;
  readonly setTagOptions: Dispatch<
    SetStateAction<
      | {
          name: string;
          assigned_scenarios_count: number;
        }[]
      | undefined
    >
  >;
}
const SCENARIO_TYPES = [
  {type: ScenarioType.CROSSLINE_COUNTING, name: ActionDisplayName.crosslinecounting},
  {type: ScenarioType.OCCUPANCY_IN_AREA, name: ActionDisplayName.occupancyInArea}
];

const getPanelTitle = (model?: string, serial?: string) => {
  if (model && serial) {
    return `${model} | ${serial}`;
  } else if (serial) {
    return serial;
  } else {
    return model;
  }
};

const queue = new PromiseQueue();

const ScenarioPanel = ({
  scenarios,
  device,
  setScenarios,
  tagOptions,
  panelOpen,
  onClose,
  setTagOptions
}: ScenarioPanelProps) => {
  const [loadingScenarioId, setLoadingScenarioId] = useState<number>();
  const {dispatchAppToast} = useToasts();
  const {organization} = usePopulatedTopbarValues();
  const openTelemetry = useOpenTelemetry();
  const styles = useStyles();
  const [pendingRequests, setPendingRequests] = useState<number>(0);

  if (
    !device ||
    !organization ||
    !tagOptions ||
    device.reachable === undefined ||
    (scenarios === undefined && device.aoaRunning && device.reachable === true)
  ) {
    return (
      <Panel
        size="large"
        title={device?.name || undefined}
        subtitle={getPanelTitle(device?.model, device?.serial)}
        titleIcon={<CameraDome28Regular />}
        isOpen={panelOpen}
        onClose={onClose}
        dataTestId="scenario-panel"
      >
        <Spinner className={styles.spinner} size="large" />
      </Panel>
    );
  }

  const columns: TableColumnDefinition<AoAScenarioInfo>[] = [
    createTableColumn<AoAScenarioInfo>({
      renderHeaderCell: () => <Text className={styles.columnText}>Scenario name</Text>,
      renderCell: item => item.name,
      columnId: 'name'
    }),
    createTableColumn<AoAScenarioInfo>({
      renderHeaderCell: () => <Text className={styles.columnText}>Scenario type</Text>,
      renderCell: item =>
        SCENARIO_TYPES.find(scenarioType => scenarioType.type === item.type)?.name || item.type,
      columnId: 'type'
    }),
    createTableColumn<AoAScenarioInfo>({
      renderHeaderCell: () => <Text className={styles.columnText}>Tags</Text>,
      renderCell: item => (
        <ScenarioTagPicker
          options={tagOptions.filter(option => !item.tags.includes(option.name))}
          selectedOptions={item.tags}
          onAdd={tagName => {
            queue.enqueue(
              () =>
                createTag({organizationArn: organization.arn, tagName}, openTelemetry).then(
                  success => {
                    if (!success) {
                      dispatchAppToast({
                        title: 'Unexpected error',
                        intent: 'error',
                        message: 'Failed to create tag, please reload the page'
                      });
                    }
                  }
                ),
              setPendingRequests
            );
            setTagOptions(tagOptions => [
              ...(tagOptions || []),
              {name: tagName, assigned_scenarios_count: 0}
            ]);
          }}
          onDelete={tagName => {
            queue.enqueue(
              () =>
                deleteTag({organizationArn: organization.arn, tagName}, openTelemetry).then(
                  success => {
                    if (!success) {
                      dispatchAppToast({
                        title: 'Unexpected Error',
                        intent: 'error',
                        message: 'Failed to delete tag, please reload the page'
                      });
                    }
                  }
                ),
              setPendingRequests
            );
            setScenarios(currentScenarios =>
              currentScenarios?.map(scenario => ({
                ...scenario,
                tags: scenario.tags.filter(t => t !== tagName)
              }))
            );
            setTagOptions(tagOptions => tagOptions?.filter(opt => opt.name !== tagName));
          }}
          onUnassign={tagName => {
            queue.enqueue(
              () =>
                unassignTag(
                  {
                    organizationArn: organization.arn,
                    tagName,
                    scenarioId: item.id,
                    serial: device.serial
                  },
                  openTelemetry
                ).then(success => {
                  if (!success) {
                    dispatchAppToast({
                      title: 'Unexpected Error',
                      intent: 'error',
                      message: 'Failed to unassign tag, please reload the page'
                    });
                  }
                }),
              setPendingRequests
            );
            setScenarios(currentScenarios =>
              currentScenarios?.map(scenario => {
                if (scenario.id === item.id) {
                  return {...scenario, tags: scenario.tags.filter(t => t !== tagName)};
                }
                return scenario;
              })
            );
            setTagOptions(currentTags =>
              currentTags?.map(tag => {
                if (tag.name === tagName) {
                  return {...tag, assigned_scenarios_count: tag.assigned_scenarios_count - 1};
                }
                return tag;
              })
            );
          }}
          onAssign={tagName => {
            queue.enqueue(
              () =>
                assignTag(
                  {
                    organizationArn: organization.arn,
                    tagName,
                    scenarioId: item.id,
                    serial: device.serial
                  },
                  openTelemetry
                ).then(success => {
                  if (!success) {
                    dispatchAppToast({
                      title: 'Unexpected Error',
                      intent: 'error',
                      message: 'Failed to assign tag, please reload the page'
                    });
                  }
                }),
              setPendingRequests
            );
            setScenarios(currentScenarios =>
              currentScenarios?.map(scenario => {
                if (scenario.id === item.id) {
                  return {...scenario, tags: [...scenario.tags, tagName]};
                }
                return scenario;
              })
            );
            setTagOptions(currentTags =>
              currentTags?.map(tag => {
                if (tag.name === tagName) {
                  return {...tag, assigned_scenarios_count: tag.assigned_scenarios_count + 1};
                }
                return tag;
              })
            );
          }}
          scenarioId={item.id}
        />
      ),
      columnId: 'tags'
    }),
    createTableColumn<AoAScenarioInfo>({
      renderHeaderCell: () => <Text className={styles.columnText}>Report</Text>,
      renderCell: item =>
        loadingScenarioId === item.id ? (
          <Spinner className={styles.spinner} size="extra-small" />
        ) : (
          <Switch
            data-testid={item.id + '-report-switch'}
            disabled={loadingScenarioId ? true : false}
            checked={item.reportingEnabled}
            onChange={() => {
              if (!organization) {
                return;
              }
              setLoadingScenarioId(item.id);
              const enabledScenarios =
                scenarios
                  ?.filter(scenario => scenario.reportingEnabled && scenario.id !== item.id)
                  .map(scenario => ({
                    id: scenario.id,
                    deviceId: scenario.deviceId
                  })) || [];
              if (!item.reportingEnabled) {
                enabledScenarios.push({id: item.id, deviceId: item.deviceId});
              }
              const payload = {
                organizationId: organization.id,
                devices: [
                  {
                    serial: device.serial,
                    scenarios: enabledScenarios
                  }
                ]
              };
              enableReporting(payload, openTelemetry)
                .then(success => {
                  setLoadingScenarioId(undefined);
                  if (success) {
                    setScenarios(currentScenarios =>
                      currentScenarios?.map(aoaScenario =>
                        aoaScenario.id === item.id
                          ? {...aoaScenario, reportingEnabled: !aoaScenario.reportingEnabled}
                          : aoaScenario
                      )
                    );
                    dispatchAppToast({
                      title: 'Success',
                      intent: 'success',
                      message: `Successfully ${item.reportingEnabled ? 'disabled' : 'enabled'} reporting`
                    });
                  } else {
                    dispatchAppToast({
                      title: 'Error',
                      intent: 'error',
                      message: 'Failed to configure reporting'
                    });
                  }
                })
                .finally(() => setLoadingScenarioId(undefined));
            }}
          />
        ),
      columnId: 'report'
    })
  ];

  return (
    <Panel
      size="large"
      title={device.name || undefined}
      subtitle={getPanelTitle(device.model, device.serial)}
      titleIcon={<CameraDome28Regular />}
      isOpen={panelOpen}
      onClose={onClose}
      dataTestId="scenario-panel"
    >
      <div className={styles.progressBarContainer} data-testid={'progress-bar-' + pendingRequests}>
        {pendingRequests > 1 && <ProgressBar />}
      </div>
      {device.reachable === false ? (
        <EmptyView.NoConnection title="Device is not reachable">
          Make sure that device is connected and onboarded
        </EmptyView.NoConnection>
      ) : (
        <Accordion collapsible defaultOpenItems="1">
          <AccordionItem value="1">
            <AccordionHeader size="large" className={styles.accordionHeader}>
              AXIS Object Analytics
            </AccordionHeader>
            <AccordionPanel>
              {!device.aoaRunning ? (
                <EmptyView.Data title="Analytics is not enabled">
                  Start and configure AXIS Object Analytics for the device
                </EmptyView.Data>
              ) : scenarios?.length === 0 ? (
                <EmptyView.NoMatch title="There are no scenarios">
                  Use AXIS Object Analytics to set up compatible scenarios
                </EmptyView.NoMatch>
              ) : (
                <DataGrid
                  className={styles.dataGrid}
                  items={scenarios || []}
                  columns={columns}
                  data-testid="scenario-table"
                  columnSizingOptions={{tags: {defaultWidth: 400}}}
                  resizableColumns
                >
                  <DataGridHeader>
                    <DataGridRow>
                      {({renderHeaderCell}) => (
                        <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                      )}
                    </DataGridRow>
                  </DataGridHeader>
                  <DataGridBody<AoAScenarioInfo>>
                    {({item, rowId}) => (
                      <DataGridRow<AoAScenarioInfo>
                        data-testid={item.id + '-scenario-table-row'}
                        key={rowId}
                      >
                        {({renderCell}) => <DataGridCell>{renderCell(item)}</DataGridCell>}
                      </DataGridRow>
                    )}
                  </DataGridBody>
                </DataGrid>
              )}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}
    </Panel>
  );
};

export default ScenarioPanel;
