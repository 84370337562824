import {useCallback, useEffect} from 'react';
import {useLocation, useNavigate, Routes, Route, useSearchParams} from 'react-router-dom';
import {
  SelectTabEventHandler,
  Tab,
  TabList,
  Toaster,
  Tooltip,
  Text,
  TabProps as FluentTabProps
} from '@fluentui/react-components';
import {
  bundleIcon,
  CameraDomeFilled,
  CameraDomeRegular,
  HomeFilled,
  HomeRegular,
  PlugConnectedFilled,
  PlugConnectedRegular
} from '@fluentui/react-icons';

import DevicesView from './views/Devices';
import HomeView from './views/Home';
import {defaultToasterId} from './hooks/useToasts';
import Reporting from './views/Reporting';
import Superset from './views/Superset';
import {useAppStyles} from './style/appStyles';
import {useNavigationStyles, useTabStyles} from '@axiscommunications/fluent-styles';
import {AuthStatus, useAuth} from '@axteams-one/auth-provider';
import {PopulatedTopbar} from '@axteams-one/populated-topbar';

const HomeIcon = bundleIcon(HomeFilled, HomeRegular);
const DevicesIcon = bundleIcon(CameraDomeFilled, CameraDomeRegular);
const PlugConnectedIcon = bundleIcon(PlugConnectedFilled, PlugConnectedRegular);

const experimental = () => {
  const value = localStorage.getItem('experimental');
  switch (value) {
    case null:
    case 'false':
      return false;
    case 'true':
      return true;
    default:
      console.warn(
        `Expected local storage item "experimental" to be either "false" or "true", but got "${value}"`
      );
      return false;
  }
};

const Main = () => {
  const appStyles = useAppStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const navStyles = useNavigationStyles();
  const [searchParams] = useSearchParams();
  const {status, login} = useAuth();

  const routes = [
    {id: 'home', path: '/', icon: <HomeIcon />, label: 'Home', testid: 'menu-home-tab'},
    {
      id: 'devices',
      path: '/devices',
      icon: <PlugConnectedIcon />,
      label: 'Devices',
      testid: 'menu-devices-tab'
    }
  ];

  if (experimental()) {
    routes.push({
      id: 'configuration',
      path: '/configuration',
      icon: <DevicesIcon />,
      label: 'Configuration',
      testid: 'menu-configuration-tab'
    });
  }

  const currentRoute = routes.find(({path}) => path === location.pathname);

  const handleTabClick = useCallback<SelectTabEventHandler>(
    (_, {value}) => {
      const traceparent = searchParams.get('traceparent');
      return navigate((value as string) + (traceparent ? `?traceparent=${traceparent}` : ''));
    },
    [navigate, searchParams]
  );
  useEffect(() => {
    if (status !== AuthStatus.Authenticated && status !== AuthStatus.Uninitialized) {
      login();
    }
  }, [status, login]);

  if (status !== AuthStatus.Authenticated) {
    return null;
  }
  if (location.pathname === '/superset') {
    return <Superset />;
  }

  return (
    <>
      <PopulatedTopbar /**@todo: implement support for about, release notes, news and status */
        helpArea={{
          about: {}
        }}
      />
      <div className={appStyles.body}>
        <Toaster
          toasterId={defaultToasterId}
          position="top"
          pauseOnHover
          pauseOnWindowBlur
          timeout={5000}
          limit={3}
        />
        <div className={navStyles.container}>
          <TabList
            size="large"
            appearance="subtle"
            onTabSelect={handleTabClick}
            vertical
            selectedValue={currentRoute?.path}
            data-testid="tab-list"
            className={navStyles.tabList}
          >
            {routes.map(tab => (
              <StyledTab
                key={tab.id}
                selected={currentRoute?.path === tab.path}
                icon={{className: navStyles.iconSize, children: tab.icon}}
                value={tab.path}
                data-testid={tab.testid}
                label={tab.label}
              />
            ))}
          </TabList>
        </div>
        <main className={appStyles.content}>
          <Routes>
            <Route path="/" element={<HomeView />} />
            <Route path="/configuration/*" element={<DevicesView />} />
            <Route path="/devices" element={<Reporting />} />
          </Routes>
        </main>
      </div>
    </>
  );
};

export default Main;

function StyledTab({
  label,
  selected,
  children,
  ...props
}: FluentTabProps & {
  readonly label: string;
  readonly selected: boolean;
}) {
  const {rootStyle} = useTabStyles({selected});

  return (
    <Tooltip
      content={{children: <Text weight="semibold">{label}</Text>}}
      positioning="after"
      relationship="label"
      withArrow
    >
      <Tab className={rootStyle} {...props}>
        {children}
      </Tab>
    </Tooltip>
  );
}
