export type Nullable<T> = T | null;
type UniqueArray<T> = T extends [infer Head, ...infer Tail]
  ? Head extends Tail[number]
    ? 'Error: Duplicate value'
    : [Head, ...UniqueArray<Tail>]
  : T;

// AOA configuration types
export type ThresholdType = 'lessThan' | 'moreThan';
export type ObjectClassificationType = 'human' | 'vehicle' | 'missing_hardhat';
export type ObjectClassificationSubType =
  | 'motorcycle/bicycle'
  | 'car'
  | 'truck'
  | 'bus'
  | 'unknown';

export enum ScenarioType {
  LINE_CROSSING = 'fence',
  CROSSLINE_COUNTING = 'crosslinecounting',
  OCCUPANCY_IN_AREA = 'occupancyInArea',
  MOTION_IN_AREA = 'motion'
}

export enum LineDirection {
  LTR = 'leftToRight',
  RTL = 'rightToLeft'
}

export enum TriggerTypes {
  EXCLUDE_AREA = 'excludeArea',
  INCLUDE_AREA = 'includeArea',
  LINE_CROSSING = 'fence',
  COUNTING_LINE = 'countingLine'
}

export type AlarmRateType = 'automatic' | 'maximizeDetections' | 'minimizeFalseAlarms';

export interface DeviceAoAInformation {
  readonly serial: string;
  readonly configuration: AoAConfiguration;
  readonly capabilities: AoACapabilities;
}

export enum ActionDisplayName {
  fence = 'Line crossing',
  crosslinecounting = 'Crossline counting',
  occupancyInArea = 'Occupany in area',
  motion = 'Motion in area'
}

export interface AoAConfiguration {
  readonly devices: Device[];
  readonly scenarios: Scenario[];
  readonly perspectives: Perspective[];
  readonly metadataOverlay: MetadataOverlay[];
}

export interface Device {
  readonly id: number;
  readonly type: 'camera' | string;
  readonly rotation: number;
  readonly isActive?: boolean;
}

export interface Scenario {
  readonly id: number;
  readonly name: string;
  readonly type: ScenarioType;
  readonly triggers: TriggerType[]; // Short-hand notation for "at least one" TriggerType.
  readonly accumulatedCounts?: AccumulatedCounts;
  readonly filters: FilterType[];
  readonly objectClassifications: ObjectClassification[];
  readonly devices: Array<{id: Device['id']; type?: 'camera'}>;
  readonly perspectives?: Array<Perspective['id']>;
  readonly presets?: number[]; // TODO: change
  readonly metadataOverlay?: MetadataOverlay['id'] | null;
  readonly alarmRate?: string;
  readonly _state?: 'remove';
  readonly passthroughConfiguration?: PassthroughThreshold;
  readonly thresholdConfiguration?: OccupancyThreshold;
  readonly eventInterval?: EventInterval;
}

export interface AccumulatedCounts {
  readonly timedResetEnabled: boolean;
}

export type FilterType =
  | FilterSizePercentage
  | FilterSizePerspective
  | FilterSwaying
  | FilterShortLived
  | FilterExcludeArea
  | FilterSpeed;

export interface FilterSizePercentage {
  readonly type: FilterTypes.SIZE_PERCENTAGE_FILTER;
  readonly width: number;
  readonly height: number;
}

export interface FilterSizePerspective {
  readonly type: FilterTypes.SIZE_PERSPECTIVE_FILTER;
  readonly width: number;
  readonly height: number;
}

export interface FilterSwaying {
  readonly type: FilterTypes.SWAYING_OBJECT_FILTER;
  readonly distance: number;
}

export interface FilterShortLived {
  readonly type: FilterTypes.SHORT_LIVED_FILTER;
  readonly time: number;
}

export interface FilterExcludeArea {
  readonly type: TriggerTypes.EXCLUDE_AREA;
  readonly vertices: ReadonlyArray<readonly [number, number]>;
}

export interface FilterSpeed {
  readonly type: 'speed';
  readonly minSpeed: number;
  readonly maxSpeed: number;
}

export interface PassthroughThreshold {
  readonly enabled: boolean;
  readonly period: number;
}

export interface OccupancyThreshold {
  readonly enabled: boolean;
  readonly triggerDelay: number;
  readonly thresholds: Threshold[];
}

export interface Threshold {
  readonly level: number;
  readonly type: ThresholdType;
}

export interface EventInterval {
  readonly enabled: boolean;
}

export interface ObjectClassification {
  readonly type: ObjectClassificationType;
  readonly subTypes?: Array<{type: ObjectClassificationSubType}>;
}

export interface PerspectiveBar {
  readonly points: [[number, number], [number, number]];
  readonly height: number;
}

export interface Perspective {
  readonly id: number;
  readonly bars: PerspectiveBar[];
}

export interface MetadataOverlay {
  readonly id: number;
  readonly resolutions?: string[];
  readonly drawOnAllResolutions?: boolean;
}

export type TriggerType = TriggerMotion | TriggerLineCrossing | TriggerCountingLine;

export interface TriggerMotion {
  readonly type: TriggerTypes.INCLUDE_AREA;
  readonly vertices: ReadonlyArray<readonly [number, number]>;
  readonly conditions?: TriggerMotionCondition[];
}

export interface TriggerLineCrossing {
  readonly type: TriggerTypes.LINE_CROSSING; // TODO: rename after backend has changed names
  readonly alarmDirection: LineDirection;
  readonly vertices: ReadonlyArray<readonly [number, number]>;
}

export interface TriggerCountingLine {
  readonly type: TriggerTypes.COUNTING_LINE;
  readonly countingDirection: LineDirection;
  readonly vertices: ReadonlyArray<readonly [number, number]>;
}

export interface TriggerMotionIndividualTimeInAreaTimesConditionData {
  readonly type: ObjectClassificationType;
  readonly time: number;
  readonly alarmTime: number;
}

export interface TriggerMotionCondition {
  readonly type: 'individualTimeInArea';
  readonly data: TriggerMotionIndividualTimeInAreaTimesConditionData[];
}

// AOA AoACapabilities types
export interface AoACapabilities {
  readonly devices: CapabilitiesDevice[];
  readonly perspective?: CapPerspective;
  readonly scenarios: CapScenarios;
  readonly objectClassifications: CapObjectClassification[];
  readonly metadataOverlay: CapMetadataOverlay;
  readonly filters: CapFilters;
  readonly triggers: CapTriggers;
  readonly presets?: CapabilitiesPresets;
}

type CapabilitiesDeviceBase = {
  readonly id: number;
  readonly name: string;
  readonly tags?: string[];
};

type Radar = {
  readonly type: 'radar';
  readonly rotation: 0;
};

type Camera = {
  readonly type: 'camera';
  readonly imageIndex: number;
  readonly rotation: 0 | 90 | 180 | 270;
  readonly resolutions: string[];
};

export type CapabilitiesDeviceCamera = CapabilitiesDeviceBase & Camera;
export type CapabilitiesDeviceRadar = CapabilitiesDeviceBase & Radar;
export type CapabilitiesDevice = CapabilitiesDeviceRadar | CapabilitiesDeviceCamera;

export interface CapPerspective {
  readonly maxNbrPerspectives: number;
  readonly minNbrBars: number;
  readonly maxNbrBars: number;
  readonly minHeight: number;
  readonly maxHeight: number;
  readonly defaultHeight: number;
}

export interface CapScenarios {
  readonly accumulatedCounts: Nullable<CapAccumulatedCounts>;
  readonly defaultDeviceId: Nullable<number>;
  readonly defaultScenario: Nullable<ScenarioType>;
  readonly defaultAlarmRate: Nullable<string>;
  readonly maxLengthName: Nullable<number>;
  readonly minLengthName: Nullable<number>;
  readonly supportedScenarios: ScenarioType[];
  readonly minNbrScenariosPerCamera: Nullable<number>;
  readonly maxNbrScenariosPerCamera: Nullable<number>;
  readonly minNbrDevices: Nullable<number>;
  readonly maxNbrDevices: Nullable<number>;
  readonly minNbrActiveDevices: Nullable<number>;
  readonly maxNbrActiveDevices: Nullable<number>;
  readonly alarmRate: Nullable<string>;
  readonly alarmRates: AlarmRateType[];
  readonly passthroughConfiguration: Nullable<CapPassthroughConfiguration>;
  readonly thresholdConfiguration: Nullable<CapThresholdConfiguration>;
  readonly eventInterval: Nullable<CapEventInterval>;
}

export interface CapObjectClassification {
  readonly type: ObjectClassificationType;
  readonly subTypes?: Array<{type: ObjectClassificationSubType}>;
}

export interface CapMetadataOverlay {
  readonly cameras: CapMetadataOverlayCamera[];
  readonly minNbrActiveCameras: Nullable<number>;
  readonly maxNbrActiveCameras: Nullable<number>;
}

export interface CapFilters {
  readonly sizePercentage: Nullable<CapFilterSizePercentage>;
  readonly sizePerspective: Nullable<CapFilterSizePerspective>;
  readonly shortLived: Nullable<CapFilterShortLived>;
  readonly swayingObject: Nullable<CapFilterSwaying>;
  readonly excludeArea: Nullable<CapFilterExcludeArea>;
  readonly speed: Nullable<CapFilterSpeed>;
}

// export type CapTriggers =
//   | CapTriggerLineCrossing
//   | CapTriggerIncludeArea
//   | CapTriggerCountingLine;

export interface CapTriggers {
  readonly includeArea: CapTriggerIncludeArea;
  readonly lineCrossing: CapTriggerLineCrossing;
  readonly countingLine: CapTriggerCountingLine;
}

export interface CapTriggerCountingLine {
  readonly defaultInstance: Nullable<Array<[number, number]>>;
  readonly defaultCountingDirection: Nullable<LineDirection>;
  readonly validCountingDirections: Nullable<Array<LineDirection>>;
  readonly minPosX: Nullable<number>;
  readonly maxPosX: Nullable<number>;
  readonly minPosY: Nullable<number>;
  readonly maxPosY: Nullable<number>;
  readonly minNbrVertices: Nullable<number>;
  readonly maxNbrVertices: Nullable<number>;
  readonly minNbrInstances: Nullable<number>;
  readonly maxNbrInstances: Nullable<number>;
  readonly type: TriggerTypes.COUNTING_LINE;
  readonly conditions?: unknown;
}
export interface CapTriggerLineCrossing {
  readonly defaultInstance: Nullable<Array<[number, number]>>;
  readonly defaultAlarmDirection: Nullable<LineDirection>;
  readonly validAlarmDirections: Nullable<Array<LineDirection>>;
  readonly minPosX: Nullable<number>;
  readonly maxPosX: Nullable<number>;
  readonly minPosY: Nullable<number>;
  readonly maxPosY: Nullable<number>;
  readonly minNbrVertices: Nullable<number>;
  readonly maxNbrVertices: Nullable<number>;
  readonly minNbrInstances: Nullable<number>;
  readonly maxNbrInstances: Nullable<number>;
  readonly type: TriggerTypes.LINE_CROSSING;
  readonly conditions?: unknown;
}

export interface CapabilitiesPresets {
  readonly maxNbrPresetsPerScenario: number;
  readonly defaultPreset: number;
}

export interface CapAccumulatedCounts {
  readonly defaultTimedResetEnabled: boolean;
}

export interface CapEventInterval {
  readonly defaultEnabled: boolean;
}

export interface CapPassthroughConfiguration {
  readonly defaultEnabled: boolean;
  readonly minPeriod: number;
  readonly maxPeriod: number;
}
export interface CapThresholdConfiguration {
  readonly defaultEnabled: boolean;
  readonly defaultType: ThresholdType;
  readonly minLevelWithLessThanType: number;
  readonly minLevelWithMoreThanType: number;
  readonly maxLevel: number;
  readonly maxTriggerDelay: number;
  readonly minTriggerDelay: number;
  readonly defaultTriggerDelay: number;
  readonly defaultLevel: number;
}

export interface CapMetadataOverlayCamera {
  readonly id: number;
  readonly resolutions: string[];
  readonly canDrawInSpecifiedResolutions: boolean;
}

export interface CapFilterSizePercentage {
  readonly defaultWidth: number;
  readonly defaultHeight: number;
  readonly minWidth: number;
  readonly maxWidth: number;
  readonly minHeight: number;
  readonly maxHeight: number;
  readonly type?: FilterTypes.SIZE_PERCENTAGE_FILTER;
}

export interface CapFilterSizePerspective {
  readonly defaultWidth: number;
  readonly defaultHeight: number;
  readonly minWidth: number;
  readonly maxWidth: number;
  readonly minHeight: number;
  readonly maxHeight: number;
  readonly type?: 'sizePerspective';
}

export interface CapFilterShortLived {
  readonly defaultTime: number;
  readonly minTime: number;
  readonly maxTime: number;
  readonly type?: 'timeShortLivedLimit';
}

export interface CapFilterSwaying {
  readonly defaultDistance: number;
  readonly minDistance: number;
  readonly maxDistance: number;
  readonly type?: 'distanceSwayingObject';
}

export interface CapFilterExcludeArea {
  readonly defaultInstance: Array<[number, number]>;
  readonly minPosX: number;
  readonly maxPosX: number;
  readonly minPosY: number;
  readonly maxPosY: number;
  readonly minNbrVertices: number;
  readonly maxNbrVertices: number;
  readonly minNbrInstances: number;
  readonly maxNbrInstances: number;
  readonly type?: TriggerTypes.EXCLUDE_AREA;
}

export interface CapFilterSpeed {
  readonly defaultMinSpeed: number;
  readonly defaultMaxSpeed: number;
  readonly minSpeed: number;
  readonly maxSpeed: number;
  readonly type?: 'speed';
}

export interface CapTriggerIncludeArea {
  readonly defaultInstance: Nullable<Array<[number, number]>>;
  readonly minPosX: Nullable<number>;
  readonly maxPosX: Nullable<number>;
  readonly minPosY: Nullable<number>;
  readonly maxPosY: Nullable<number>;
  readonly minNbrVertices: Nullable<number>;
  readonly maxNbrVertices: Nullable<number>;
  readonly minNbrInstances: Nullable<number>;
  readonly maxNbrInstances: Nullable<number>;
  readonly type: 'motion';
  readonly conditions?: CapTriggerIncludeAreaConditions;
}

export interface CapTriggerIncludeAreaConditions {
  readonly defaultConditionType: 'individualTimeInArea';
  readonly individualTimeInArea: CapTriggerIncludeAreaIndividualTimeInAreaTimes;
}

export type CapTriggerIncludeAreaIndividualTimeInAreaTimes = {
  [key in ObjectClassificationType]: CapTriggerIncludeAreaIndividualTimeInAreaTimesClassification;
};

export interface CapTriggerIncludeAreaIndividualTimeInAreaTimesClassification {
  readonly defaultAlarmTime: number;
  readonly defaultTime: number;
  readonly maxTime: number;
  readonly minTime: number;
}

export interface LineCrossingAoAScenario extends AoAScenarioBase {
  readonly type: ScenarioType.LINE_CROSSING;
  readonly triggers: [TriggerLineCrossing];
  readonly filters: UniqueArray<
    (FilterSizePercentage | FilterSizePerspective | FilterShortLived)[]
  >;
  readonly objectClassifications: ObjectClassification[];
}

export interface CrosslineCountingAoAScenario extends AoAScenarioBase {
  readonly type: ScenarioType.CROSSLINE_COUNTING;
  readonly triggers: [TriggerCountingLine];
  readonly filters: UniqueArray<
    [FilterSizePercentage, FilterSizePerspective, FilterShortLived] | []
  >;
  readonly objectClassifications: ObjectClassification[];
  readonly accumulatedCounts: AccumulatedCounts;
  readonly eventInterval: EventInterval;
  readonly passthroughConfiguration: PassthroughThreshold;
}

export interface OccupancyInAreaScenario extends AoAScenarioBase {
  readonly type: ScenarioType.OCCUPANCY_IN_AREA;
  readonly triggers: [TriggerMotion];
  readonly filters: UniqueArray<
    (
      | FilterSizePercentage
      | FilterSizePerspective
      | FilterSwaying
      | FilterShortLived
      | FilterExcludeArea
    )[]
  >;
  readonly objectClassifications: ObjectClassification[];
  readonly thresholdConfiguration: OccupancyThreshold;
  readonly eventInterval: EventInterval;
}

export interface MotionInAreaScenario extends AoAScenarioBase {
  readonly type: ScenarioType.MOTION_IN_AREA;
  readonly triggers: [TriggerMotion];
  readonly filters: UniqueArray<
    (
      | FilterSizePercentage
      | FilterSizePerspective
      | FilterSwaying
      | FilterShortLived
      | FilterExcludeArea
    )[]
  >;
  readonly objectClassifications: ObjectClassification[];
}

export type AoAScenario =
  | LineCrossingAoAScenario
  | CrosslineCountingAoAScenario
  | OccupancyInAreaScenario
  | MotionInAreaScenario;

interface AoAScenarioBase {
  readonly id: number;
  readonly name: string;
  readonly type: ScenarioType;
  readonly objectClassifications: ObjectClassification[]; // Maybe unnecessary. Might be good to specify hard empty scenario for any-motion later
  readonly devices: [{id: Device['id']; type?: 'camera'}];
  readonly metadataOverlay?: MetadataOverlay['id'] | null;
  readonly perspectives?: Array<Perspective['id']>;
  readonly presets?: number[]; // TODO: change
  readonly _state?: 'remove';
}

export type DashboardInfo = {uuid: string; name: string; domain: string};

export interface DashboardDataPayload {
  readonly organizationArn: string;
}
export interface GuestTokenPayload {
  readonly organizationArn: string;
  readonly dashboardId: string;
  readonly selectedResourceGroup: string;
}

export interface GetDeviceInfosPayload {
  readonly organizationArn: string;
}

export interface GetScenarioInfosPayload {
  readonly serial: string;
  readonly organizationId: string;
}

export interface DeviceInfo {
  aoaRunning?: boolean;
  arn: string;
  iotCoreConnected: boolean;
  model?: string;
  piaItemId?: string;
  serial: string;
  name: null | string;
  reachable?: boolean;
}

export enum DeviceStatus {
  Connected,
  Reachable,
  Unreachable,
  Unknown
}

export type AoAScenarioInfo = {
  deviceId: number;
  id: number;
  name: string;
  type: string;
  reportingEnabled: boolean;
  tags: string[];
};

export enum ACAPS {
  AOA = 'AXIS Object Analytics',
  AOA_BETA = 'AXIS Object Analytics beta'
}

export enum FilterTypes {
  SIZE_PERCENTAGE_FILTER = 'sizePercentage',
  SIZE_PERSPECTIVE_FILTER = 'sizePerspective',
  SWAYING_OBJECT_FILTER = 'distanceSwayingObject',
  SHORT_LIVED_FILTER = 'timeShortLivedLimit',
  SPEED_FILTER = 'speed'
}

export interface EnableReportingPayload {
  readonly organizationId: string;
  readonly devices: {
    readonly serial: string;
    readonly scenarios: {
      readonly id: number;
      readonly deviceId: number;
    }[];
  }[];
}

export interface CreateTagPayload {
  readonly organizationArn: string;
  readonly tagName: string;
}

export interface DeleteTagPayload {
  readonly organizationArn: string;
  readonly tagName: string;
}

export interface FetchTagsPayload {
  readonly organizationArn: string;
}
export interface AssignTagPayload {
  readonly organizationArn: string;
  readonly tagName: string;
  readonly scenarioId: number;
  readonly serial: string;
}

export interface UnassignTagPayload {
  readonly organizationArn: string;
  readonly tagName: string;
  readonly scenarioId: number;
  readonly serial: string;
}
